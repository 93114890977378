


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import CircleCircle from '@/views/decorate/background/CircleCircle.vue';

@Component({
    components: { CircleCircle }
})
export default class ShapedElControl extends Vue {
    qty = 5;
    bkColor = '#f0f8ff';
    shadowColor = '#508fb9';
    opacity = [0.2, 0.9];
    borderRadius = 50;
    shape = 'regular';
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 6;
}
