import { Shipping } from './../../data/Shipping';
import { CartItem } from '@/data/CartItem';
import { Nullable } from '@/types';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Store from '../index';
import Vue from 'vue';

@Module({
    dynamic: true,
    store: Store,
    name: 'shopModule',
    namespaced: true
})
export default class ShopModule extends VuexModule {
    public cartItems: Array<CartItem> = [];
    public shipping = new Shipping();

    @Mutation
    public setShippingName (name: string): void {
        this.shipping.name = name;
    }

    @Mutation
    public addItem (cartItem: CartItem): void {
        this.cartItems.push(cartItem);
    }

    @Mutation
    public removeCartItem (cartItem: CartItem): void {
        this.cartItems.remove(cartItem);
    }

    @Mutation
    public removeAllCartItem (): void {
        this.cartItems = [];
    }

    @Action
    public doLoadShipping (): void {
        let shipping = localStorage.getItem('shipping');
        if (shipping) {
            try {
                this.shipping.setShipping(JSON.parse(shipping));
            } catch (error) {
                localStorage.removeItem('shipping');
            }
        }
    }

    @Action
    public doSaveShipping (): void {
        localStorage.setItem('shipping', JSON.stringify(this.shipping.toStorage()));
    }

    @Action
    public doAddItem (cartItem: CartItem): void {
        this.addItem(cartItem);
        localStorage.setItem('cartItems', this.cartStorage);
    }

    @Action
    public doRemoveCartItem (cartItem: CartItem): void {
        this.removeCartItem(cartItem);
        localStorage.setItem('cartItems', this.cartStorage);
    }

    @Action
    public doRemoveAllCartItem (): void {
        this.removeAllCartItem();
        localStorage.setItem('cartItems', this.cartStorage);
    }

    get cartStorage (): string {
        return JSON.stringify(this.cartItems.map(c => c.toStorage()));
    }

    public get totalPrice (): number {
        let total = 0;
        this.cartItems.forEach(item => { total += item.qty * item.stock.price; });
        return total;
    }

    public get cartItemCount (): number {
        return this.cartItems.length;
    }
}
