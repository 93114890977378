































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Stock } from '@/data/Stock';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { CartItem } from '@/data/CartItem';
import Dollar from '@/views/util/Dollar.vue';
import { requiredRules, emailRules } from '@/views/util/Rules';

declare var paypal: any;
const shopModule = getModule(ShopModule);

@Component({
    components: { Dollar }
})
export default class CheckoutDetail extends Vue {
    public requiredRules = requiredRules();
    public emailRules = emailRules();
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 6;

    public get cartItemCount (): number {
        return shopModule.cartItemCount;
    }

    public get cartItems (): Array<CartItem> {
        return shopModule.cartItems;
    }

    public get subTotal (): number {
        return shopModule.totalPrice;
    }

    public next () {
        if (this.subTotal > 0 && (this.$refs.form as any).validate()) {
            shopModule.doSaveShipping();
            this.$emit('doneCheckout');
        }
    }

    public get shipping () {
        return shopModule.shipping;
    }

    public removeCartItem (item: CartItem) {
        shopModule.doRemoveCartItem(item);
    }
}
