export interface IShipping {
    name: string,
    email: string,
    address: string,
    suburb: string,
    state: string,
    postcode: string
}

export class Shipping {
    public name = '';
    public email = '';
    public address = '';
    public suburb = '';
    public state = '';
    public postcode = '';
    public note = '';

    public setShipping (iShipping: IShipping) {
        this.name = iShipping.name;
        this.email = iShipping.email;
        this.address = iShipping.address;
        this.suburb = iShipping.suburb;
        this.postcode = iShipping.postcode;
        this.state = iShipping.state;
    }

    public toStorage (): any {
        return { name: this.name, email: this.email, address: this.address, suburb: this.suburb, postcode: this.postcode, state: this.state };
    }

    public get fullAddress (): string {
        return this.address + ' ' + this.suburb + ' ' + this.state + ' ' + this.postcode;
    }
}
