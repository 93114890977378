import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SITE,
    withCredentials: true
});

// axiosInstance.defaults.xsrfCookieName = 'XSRF-TOKEN';
// axiosInstance.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

// axiosInstance.defaults.withCredentials = true;

// axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueAxios, axiosInstance);

// Vue.prototype.$http = axiosInstance;
