import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Store from '../index';

export enum SnackBarStatus {
    Success, Error, Info
}

@Module({
    dynamic: true,
    store: Store,
    name: 'uiModule',
    namespaced: true
})
export default class UIModule extends VuexModule {
    public isShowMsgBar = false;
    public msgBarMsg = '';
    public msgBarStatus: SnackBarStatus = SnackBarStatus.Success;
    public msgBarTimeOut = 2000;
    public timerId = 0;

    @Mutation
    public showMsgBar (payload: { status?: SnackBarStatus, msg: string }): void {
        this.msgBarStatus = payload.status ? payload.status : SnackBarStatus.Success;
        this.msgBarMsg = payload.msg;
        this.isShowMsgBar = true;
    }

    @Mutation
    public setTimer (id: number) {
        this.timerId = id;
    }

    @Action
    public doShowMsgBar (payload: { status?: SnackBarStatus, msg: string }) {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.showMsgBar(payload);
        this.setTimer(setTimeout(() => {
            this.closeMsgBar();
        }, this.msgBarTimeOut));
    }

    @Mutation
    public closeMsgBar (): void {
        this.isShowMsgBar = false;
    }
}
