
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import UIModule, { SnackBarStatus } from '@/store/modules/UIModule';
import { getModule } from 'vuex-module-decorators';
import CircleCircle from '@/views/decorate/background/CircleCircle.vue';
import FireFly from '@/views/decorate/background/FireFly.vue';

@Component({
    components: { CircleCircle, FireFly },
    metaInfo () {
        return {
            title: 'Ge Zhan\'s Site',
            meta: [
                { name: 'description', content: 'Hi, I\'m Ge Zhan, welcome to my site, here you can purchase some good quality lens, and i will put some of my project and tutorials about coding soon.' },
                { name: 'keywords', content: 'camrea,lens,program,game,vue,laravel,python,typescript,javascript,unity' }
            ]
        };
    }
})
export default class Home extends Vue {
    circleCircleSize = window.innerWidth / 4;
    fireflyBkColor = 'aliceblue';
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 3;

    public get circleCircleStyle () {
        return {
            left: -this.circleCircleSize / 2 + 'px',
            top: -this.circleCircleSize / 2 + 'px'
        };
    }

    public gotoProject () {
        // getModule(UIModule).doShowMsgBar({ msg: 'Thank you for click, projects will come in soon, please come back later' });
        // for now just goto test stock for 3d viewer
        window.open(this.$site + 'project-mesh-viewer', '_blank');
    }

    listItems = [
        { icon: 'mdi-lightbulb-on-outline', text: 'Welcome to my site, here you can find items for selling , tutorials I made and some fun project I\'m working on.', color: 'yellow' }
        // { icon: 'mdi-playlist-check', text: 'A site for selling my items', color: 'yellow' },
        // { icon: 'mdi-clock-outline', text: 'A site for writing some tutorial and notes about coding', color: 'blue-grey', iconColor: 'white' },
        // { icon: 'mdi-clock-outline', text: 'A site for hosting personal project and ideas', color: 'blue-grey', iconColor: 'white' }
    ]
}

