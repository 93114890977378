export interface ICommentRecord {
    id: number,
    name: string,
    email: string,
    comment: string,
    notify: boolean,
    createdAt: string,
    updatedAt: string
}

export class Comment {
    public id: number = 0;
    public name: string = '';
    public email: string = '';
    public comment: string = '';
    public notify: boolean = false;
    public createdAt!: Date;
    public updatedAt!: Date;

    constructor (record?: ICommentRecord) {
        if (record) {
            this.setRecord(record);
        }
    }

    public setRecord (record: ICommentRecord): void {
        this.id = record.id;
        this.name = record.name;
        this.email = record.email;
        this.comment = record.comment;
        this.notify = record.notify;

        this.createdAt = new Date(record.createdAt);
        this.updatedAt = new Date(record.updatedAt);
    }

    public getSaveData (): any {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            comment: this.comment,
            notify: this.notify
        };
    }
}
