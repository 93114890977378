


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FireFly from '@/views/decorate/background/FireFly.vue';
import { Article } from '@/data/Article';

@Component({
    components: { FireFly },
    metaInfo () {
        return {
            title: 'CSS FireFly Background',
            meta: [
                { name: 'description', content: 'Firefly Background.' },
                { name: 'keywords', content: 'firefly,css,sass,vue,tutorial' }
            ]
        };
    }
})
export default class TFirefly extends Vue {
    @Prop() readonly article !: Article;

    fireflyBkColor = '#000000';
    fireflyLightColor = '#0000FF';
    qty = 15;
}
