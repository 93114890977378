import Vue from 'vue';
import VueRouter from 'vue-router';
import Stocks from '@/views/public/stock/Stocks.vue';
import StockDetail from '@/views/public/stock/StockDetail.vue';
import Checkout from '@/views/public/order/Checkout.vue';
import Home from '@/views/public/Home.vue';
import Contact from '@/views/public/contact/Contact.vue';
import TutorialList from '@/views/public/tutorial/TutorialList.vue';
import Article from '@/views/public/tutorial/Article.vue';

Vue.use(VueRouter);

function getRoute (path:string, name: string, comp: {component?: any, components?: any}, auth: boolean) {
    let route: any = { path, name, meta: { auth } };
    if (comp.component) {
        route.component = comp.component;
    } else {
        route.components = comp.components;
    }

    return route;
}

const routes = [
    getRoute('/', 'home', { component: Home }, false),
    getRoute('/contact', 'contact', { component: Contact }, false),
    getRoute('/store/lens', 'stocks', { component: Stocks }, false),
    getRoute('/store/lens/:urlName', 'stock', { component: StockDetail }, false),
    getRoute('/checkout', 'checkout', { component: Checkout }, false),
    getRoute('/tutorial', 'tutorial', { component: TutorialList }, false),
    getRoute('/article/:urlName', 'article', { component: Article }, false)
    // getRoute('/tutorial/css-animate-firefly-background', 't-firefly', { component: TFirefly }, false)
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
