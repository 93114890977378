import Vue from 'vue';
import '@/ClassComponentHooks';
import router from '@/router/public';
import store from '@/store';
import { vuetify } from '@/plugins';
import '@/components/public';
import '@/types';
import '@/vue/filter';
import Meta from 'vue-meta';
import App from './App.vue';
import '@/styles/prism.css';
import VueObserveVisibility from 'vue-observe-visibility';
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
    config: { id: 'UA-165469355-1' },
    params: {
        send_page_view: true
    }
}, router);

// import all public comp
const requireComponent = require.context('@/views/public', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');

    Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.use(Meta);
// Vue.use(VueHighlightJS);
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;
declare module 'vue/types/vue' {
    interface Vue {
        $site: string
    }
}

Vue.prototype.$site = process.env.VUE_APP_SITE;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
