



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Stock } from '@/data/Stock';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { CartItem } from '@/data/CartItem';

@Component({
    metaInfo () {
        return {
            title: 'Lens Store',
            meta: [
                { name: 'description', content: 'Welcome to the lens store, surely you will find some good quality telephoto camera lens here. All the lens compatible with all brand of SLR camera, including Canon Nikon Sony NEX Pentax Olympus Panasonic' },
                { name: 'keywords', content: 'Ge Zhan Store Camera Lens' }
            ]
        };
    }
})
export default class StockList extends Vue {
    stocks: Array<Stock> = [];
    loading = false;
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 3;

    public created (): void {
        this.getStockList();
    }

    private getStockList (): void {
        this.loading = true;
        this.stocks = [];
        Vue.axios.post('/api/stock/getStocks').then(response => {
            response.data.forEach((stock: any) => this.stocks.push(new Stock(stock)));
            this.loading = false;
        });
    }

    public gotoStockDetail (stock: Stock): void {
        this.$router.push({ name: 'stock', params: { urlName: stock.urlName } });
    }

    public addToCart (stock: Stock) {
        const shopModule = getModule(ShopModule);
        let cartItem = new CartItem(stock, 1);
        shopModule.doAddItem(cartItem);
    }
}

