export type Nullable<T> = T | null;

declare global {
    interface Array<T> {
        remove (e: T): Array<T>
    }
}

if (!Array.prototype.remove) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.remove = function<T> (e: T): T[] {
        const index = this.indexOf(e);
        return index === -1 ? this : this.splice(index, 1);
    };
}
