import { IStockRecord, Stock } from './Stock';
export interface IOrderItem {
    id: number,
    qty: number,
    price: number,
    stock: IStockRecord
}

export class OrderItem {
    public id: number = 0;
    public qty: number = 0;
    public price: number = 0;
    public stock: Stock;

    constructor (iOrderItem: IOrderItem) {
        this.id = iOrderItem.id;
        this.qty = iOrderItem.qty;
        this.price = iOrderItem.price;
        this.stock = new Stock(iOrderItem.stock);
    }
}
