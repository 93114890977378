







import Vue from 'vue';
import Component from 'vue-class-component';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { Stock, IStockRecord } from '../../data/Stock';
import { CartItem } from '../../data/CartItem';

@Component
export default class App extends Vue {
    public mounted () {
        const shopModule = getModule(ShopModule);
        let items = localStorage.getItem('cartItems');
        let cartItems: Array<{ stockId: number, qty: number}> = [];
        shopModule.doRemoveAllCartItem();
        shopModule.doLoadShipping();

        if (items) {
            try {
                JSON.parse(items).forEach((item: { stockId: number, qty: number}) => {
                    cartItems.push(item);
                });
            } catch (error) {
                localStorage.removeItem('cartItems');
            }
        }

        let data = { stockIds: cartItems.map(c => c.stockId) };
        Vue.axios.post('api/siteBoost', data).then(response => {
            let stockObjs = response.data.stocks;
            let stocks: Array<Stock> = [];
            if (stockObjs) {
                stockObjs.forEach((obj:IStockRecord) => {
                    stocks.push(new Stock(obj));
                });
            }

            cartItems.forEach(item => {
                let stock = stocks.find(s => s.id === item.stockId);
                if (stock) {
                    shopModule.doAddItem(new CartItem(stock, item.qty));
                }
            });
        });
    }
};
