export interface ITagRecord {
    id: number,
    tag: string
}

export class Tag {
    public id: number = 0;
    public tag: string = '';

    constructor (tagRecord?: ITagRecord) {
        if (tagRecord) {
            this.setTagRecord(tagRecord);
        }
    }

    public setTagRecord (record: ITagRecord): void {
        this.id = record.id;
        this.tag = record.tag;
    }

    public getSaveData (): any {
        return {
            id: this.id,
            tag: this.tag
        };
    }
}
