





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PhotoViewer extends Vue {
    currentPhoto = 0;
    tabWidth = this.$vuetify.breakpoint.mdAndDown ? 80 : 120;
    tabHeight = this.$vuetify.breakpoint.mdAndDown ? 50 : 70;
    height = this.$vuetify.breakpoint.mdAndDown ? 280 : 600;

    @Prop()
    public photos!: Array<{ url: string }>;
}
