








import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import ShopModule from '@/store/modules/ShopModule';
import UIModule, { SnackBarStatus } from '@/store/modules/UIModule';
import Cart from '@/views/public/order/Cart.vue';

const uiModule = getModule(UIModule);
const shopModule = getModule(ShopModule);

@Component({
    components: { Cart }
})
export default class CoreView extends Vue {
    public get msgBarColor (): string {
        switch (uiModule.msgBarStatus) {
            case SnackBarStatus.Success:
                return 'success';
            case SnackBarStatus.Error:
                return 'error';
            case SnackBarStatus.Info:
                return 'info';
        }

        return 'primary';
    }

    public get showCart () {
        return shopModule.cartItemCount > 0;
    }

    public get msgBarTimeOut (): number {
        return uiModule.msgBarTimeOut;
    }

    public get isShowMsgBar (): boolean {
        return uiModule.isShowMsgBar;
    }

    public set isShowMsgBar (isShow: boolean) {
        uiModule.closeMsgBar();
    }

    public get msgBarMsg (): string {
        return uiModule.msgBarMsg;
    }

    public closeMsgBar (): void {
        uiModule.closeMsgBar();
    }
};
