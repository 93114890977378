










































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Stock } from '@/data/Stock';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { CartItem } from '@/data/CartItem';
import Dollar from '@/views/util/Dollar.vue';

const shopModule = getModule(ShopModule);

@Component({
    components: { Dollar }
})
export default class Cart extends Vue {
    public showCart = false;
    minWidth = this.$vuetify.breakpoint.mdAndDown ? '100%' : 600;

    public get cartItemCount (): number {
        return shopModule.cartItemCount;
    }

    public get cartItems (): Array<CartItem> {
        return shopModule.cartItems;
    }

    public get subTotal (): number {
        return shopModule.totalPrice;
    }

    public removeCartItem (item: CartItem) {
        shopModule.doRemoveCartItem(item);
    }

    public checkout () {
        this.showCart = false;
        this.$router.push({ name: 'checkout' });
    }
}
