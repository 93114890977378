export interface IStockMesh {
    id: number,
    folder: string,
    filename: string,
    params: string
}

export class StockMesh {
    public id: number = 0;
    public folder: string = '';
    public filename: string = '';
    public params: any = {};

    constructor (obj?: IStockMesh) {
        if (obj) {
            this.id = obj.id;
            this.folder = obj.folder;
            this.filename = obj.filename;
            this.params = JSON.parse(obj.params);
        }
    }

    public getSaveData (): any {
        return {
            id: this.id,
            folder: this.folder,
            filename: this.filename,
            params: JSON.stringify(this.params)
        };
    }
}
