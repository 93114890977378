



































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Stock } from '@/data/Stock';
import PhotoViewer from '@/views/util/PhotoViewer.vue';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { CartItem } from '@/data/CartItem';
import Dollar from '@/views/util/Dollar.vue';
import Enquiry from '@/views/public/contact/Enquiry.vue';

@Component({
    components: { PhotoViewer, Dollar, Enquiry },
    metaInfo () {
        return {
            title: (this as any).stock.name,
            meta: [
                { name: 'description', content: (this as any).stock.description },
                { name: 'keywords', content: (this as any).stock.name }
            ]
        };
    }
})
export default class StockDetail extends Vue {
    public stock: Stock = new Stock();
    public currentPhoto = 0;
    public showEnquiry = false;
    show3DViewer = false;
    frameHeight = window.innerHeight * 0.8 + 'px';

    col1 = this.$vuetify.breakpoint.mdAndDown ? 12 : 7;
    col2 = this.$vuetify.breakpoint.mdAndDown ? 12 : 5;

    public created (): void {
        Vue.axios.get('api/stock/get_by_url_name/' + this.$route.params.urlName).then(response => {
            this.stock.setStockRecord(response.data);
        });
    }

    public get jsonld () {
        if (this.stock.id) {
            return {
                '@context': 'https://schema.org',
                '@type': 'Product',
                'name': this.stock.name,
                'image': [ this.stock.coverImg.url ],
                description: this.stock.description,
                brand: 'Kelda',
                'review': {
                    '@type': 'Review',
                    'reviewRating': {
                        '@type': 'Rating',
                        'ratingValue': '5',
                        'bestRating': '5'
                    },
                    'author': {
                        '@type': 'Person',
                        'name': 'Ge Zhan'
                    }
                },
                'offers': {
                    '@type': 'Offer',
                    'priceCurrency': 'AUD',
                    'price': this.stock.price,
                    'itemCondition': 'https://schema.org/NewCondition',
                    'availability': 'https://schema.org/InStock',
                    'priceValidUntil': '2021-01-01',
                    'url': this.$site + 'store/lens/' + this.stock.urlName
                },
                'sku': this.stock.sku
            };
        } else {
            return {};
        }
    }

    public addToCart () {
        const shopModule = getModule(ShopModule);
        let cartItem = new CartItem(this.stock, 1);
        shopModule.doAddItem(cartItem);
    }

    public buyItNow () {
        this.addToCart();
        this.$router.push({ name: 'checkout' });
    }

    public backToShop () {
        this.$router.push({ name: 'stocks' });
    }

    public enquiry () {
        this.showEnquiry = true;
    }

    public view3D () {
        this.show3DViewer = true;
    }
}
