





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Stock } from '@/data/Stock';
import { getModule } from 'vuex-module-decorators';
import { requiredRules, emailRules } from '@/views/util/Rules';
import UIModule, { SnackBarStatus } from '@/store/modules/UIModule';

@Component
export default class Enquiry extends Vue {
    subject: string = '';
    msg: string = '';
    email: string = '';
    emailRule = [ ...requiredRules(), ...emailRules() ];
    requiredRule = requiredRules();
    loading = false;
    sent = false;

    @Prop() readonly stock!: Stock;
    @Prop() readonly isDialog!: boolean;

    public mounted () {
        if (this.stock) {
            this.subject = 'Question about item ' + this.stock.name;
        }
    }

    public save () {
        if ((this.$refs.form as any).validate()) {
            let enquiry = {
                email: this.email,
                subject: this.subject,
                msg: this.msg,
                link_id: this.stock ? this.stock.id : '',
                link_type: this.stock ? 'stock' : ''
            };

            this.loading = true;
            this.axios.post('api/contact/sendEnqiry', { enquiry }).then(response => {
                const uiModule = getModule(UIModule);
                uiModule.showMsgBar({ msg: 'Thank you for the enquiry, I will reply as soon as possible' });
                this.sent = true;
                this.$emit('close');
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
