import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({});

export default new Vuex.Store(store);

// import { user } from './user/index';
// import Vue from 'vue';
// import Vuex, { StoreOptions } from 'vuex';
// import { state } from './state';
// import { RootState } from './types';

// Vue.use(Vuex);

// const store: StoreOptions<RootState> = {
//     strict: process.env.NODE_ENV !== 'production',
//     state,
//     modules: { user }
// };

// export default new Vuex.Store<RootState>(store);
