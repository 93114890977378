// import * as _ from 'lodash';
export interface IStockPhotoRecord {
    id: number,
    name: string,
    filename: string,
    url?: string
}

export class StockPhoto {
    public id: number = 0;
    public name: string = '';
    public filename: string = '';
    public url: string = '';

    constructor (record: IStockPhotoRecord) {
        // Object.assign(this, _.mapKeys(fileRecord, (v, k) => _.camelCase(k)));
        Object.assign(this, record);
    }
}
