import { StockPhoto, IStockPhotoRecord } from './StockPhoto';
import { StockMesh, IStockMesh } from './StockMesh';

export interface IStockRecord {
    id: number,
    name: string,
    urlName: string,
    description: string,
    qty: number,
    price: number,
    photos?: Array<IStockPhotoRecord>,
    meta: string,
    sku: string,
    stockMesh?: IStockMesh
}

export class Stock {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public qty: number = 0;
    public price: number = 0;
    public photos: Array<StockPhoto>;
    public specs: Array<{ header: string, detail: string}>;
    public compatible: string = '';
    public urlName: string = '';
    public sku: string = '';
    public stockMesh?: StockMesh;

    constructor (stockRecord?: IStockRecord) {
        this.photos = [];
        this.specs = [];
        if (stockRecord) {
            this.setStockRecord(stockRecord);
        }
    }

    public setStockRecord (stockRecord: IStockRecord): void {
        this.id = stockRecord.id;
        this.name = stockRecord.name;
        this.urlName = stockRecord.urlName;
        this.description = stockRecord.description;
        this.qty = stockRecord.qty;
        this.price = stockRecord.price;
        this.sku = stockRecord.sku;

        if (stockRecord.photos) {
            stockRecord.photos.forEach(photo => {
                this.photos.push(new StockPhoto(photo));
            });
        }

        if (stockRecord.meta) {
            let meta = JSON.parse(stockRecord.meta);
            this.specs = meta.specs;
            this.compatible = meta.compatible;
        }

        if (stockRecord.stockMesh) {
            this.stockMesh = new StockMesh(stockRecord.stockMesh);
        }
    }

    public get coverImg (): StockPhoto {
        return this.photos[0];
    }

    public getSaveData (): any {
        let meta = {
            specs: this.specs,
            compatible: this.compatible
        };

        return {
            id: this.id,
            name: this.name,
            description: this.description,
            qty: this.qty,
            price: this.price,
            sku: this.sku,
            meta: JSON.stringify(meta)
        };
    }
}
