












































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Stock } from '@/data/Stock';
import { Order } from '@/data/Order';
import ShopModule from '@/store/modules/ShopModule';
import { getModule } from 'vuex-module-decorators';
import { CartItem } from '@/data/CartItem';
import Dollar from '@/views/util/Dollar.vue';
import { requiredRules, emailRules } from '@/views/util/Rules';
import UIModule, { SnackBarStatus } from '@/store/modules/UIModule';

declare var paypal: any;
const shopModule = getModule(ShopModule);

@Component({
    components: { Dollar }
})
export default class CheckoutPayment extends Vue {
    public approving = false;
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 6;

    public get cartItemCount (): number {
        return shopModule.cartItemCount;
    }

    public get cartItems (): Array<CartItem> {
        return shopModule.cartItems;
    }

    public get subTotal (): number {
        return shopModule.totalPrice;
    }

    public backToCheckout () {
        this.$emit('backToCheckout');
    }

    public get shipping () {
        return shopModule.shipping;
    }

    // public testPayment () {
    //     let postData = {
    //         orderID: '9LK98263FR277880X',
    //         items: this.cartItems.map((c: CartItem) => ({ 'stock_id': c.stock.id, qty: c.qty, price: c.stock.price })),
    //         shipping: this.shipping.fullAddress,
    //         note: this.shipping.note,
    //         name: this.shipping.name,
    //         email: this.shipping.email,
    //         totalValue: this.subTotal
    //     };

    //     Vue.axios.post('api/paymentApproved', postData).then(response => {
    //         console.log(response);
    //         let order = new Order(response.data);
    //         console.log(order);
    //         this.$emit('donePayment', order);
    //         // shopModule.doRemoveAllCartItem();
    //     });
    // }

    public mounted () {
        let items: Array<any> = [];
        this.cartItems.forEach(cartItem => {
            items.push({
                name: cartItem.stock.name,
                'unit_amount': {
                    currency_code: 'AUD',
                    value: cartItem.stock.price
                },
                quantity: cartItem.qty
            });
        });

        let shipping = {
            name: {
                full_name: 'Ge Zhan'
            },
            address: {
                'address_line_1': this.shipping.address,
                'address_line_2': '',
                'admin_area_2': this.shipping.suburb,
                'admin_area_1': this.shipping.state,
                'postal_code': this.shipping.postcode,
                'country_code': 'AU'
            }
        };

        paypal.Buttons({
            createOrder: (data: any, actions: any) => {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: 'AUD',
                            value: this.subTotal,
                            breakdown: {
                                item_total: {
                                    currency_code: 'AUD',
                                    value: this.subTotal
                                }
                            }
                        },
                        items,
                        shipping
                    }]
                });
            },
            onApprove: (data: any, actions: any) => {
                return actions.order.capture().then((details:any) => {
                    this.approving = true;
                    let postData = {
                        orderID: data.orderID,
                        items: this.cartItems.map((c: CartItem) => ({ 'stock_id': c.stock.id, qty: c.qty, price: c.stock.price })),
                        shipping: this.shipping.fullAddress,
                        note: this.shipping.note,
                        name: this.shipping.name,
                        email: this.shipping.email,
                        totalValue: this.subTotal
                    };

                    Vue.axios.post('api/paymentApproved', postData).then(response => {
                        shopModule.doRemoveAllCartItem();
                        this.$emit('donePayment', new Order(response.data));
                    }).catch(e => {
                        const uiModule = getModule(UIModule);
                        uiModule.showMsgBar({ status: SnackBarStatus.Error, msg: 'Sorry, looks something wrong in processing approving payment, please contact me if have any payment issue.' });
                    }).finally(() => {
                        this.approving = false;
                    });
                });
            }
        }).render(this.$refs.paypal);
    }
}
