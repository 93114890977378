import { IUser, User } from '@/data/User';
import { ITagRecord, Tag } from '@/data/Tag';
import { ICommentRecord, Comment } from '@/data/Comment';

export interface IArticleRecord {
    id: number,
    title: string,
    content: string,
    publish: boolean,
    urlName: string,
    createdAt: string,
    updatedAt: string,
    coverImageUrl: string,
    user: IUser,
    description: string,
    like: number,
    bookmarks: Array<{ id: string, title: string }>,
    tags: Array<ITagRecord>,
    comments: Array<ICommentRecord>
}

export class Article {
    public id: number = 0;
    public title: string = '';
    public content: string = '';
    public publish: boolean = false;
    public urlName: string = '';
    public coverImageUrl: string = '';
    public user!: User;
    public tags: Array<Tag> = [];
    public comments: Array<Comment> = [];
    public bookmarks: Array<{ title: string, id: string }> = [];
    public description: string = '';
    public like: number = 0;
    public createdAt!: Date;
    public updatedAt!: Date;

    constructor (articleRecord?: IArticleRecord) {
        if (articleRecord) {
            this.setArticleRecord(articleRecord);
        }
    }

    public setArticleRecord (articleRecord: IArticleRecord): void {
        this.id = articleRecord.id;
        this.title = articleRecord.title;
        this.content = articleRecord.content;
        this.publish = articleRecord.publish;
        this.urlName = articleRecord.urlName;
        this.coverImageUrl = articleRecord.coverImageUrl;
        this.description = articleRecord.description;
        this.like = articleRecord.like;
        this.bookmarks = articleRecord.bookmarks;
        this.user = new User(articleRecord.user.id, articleRecord.user.name, articleRecord.user.email);
        this.createdAt = new Date(articleRecord.createdAt);
        this.updatedAt = new Date(articleRecord.updatedAt);

        if (articleRecord.tags) {
            articleRecord.tags.forEach(tagRecord => {
                this.tags.push(new Tag(tagRecord));
            });
        }

        if (articleRecord.comments) {
            articleRecord.comments.forEach(record => {
                this.comments.push(new Comment(record));
            });
        }
    }

    public getSaveData (): any {
        return {
            id: this.id,
            title: this.title,
            content: this.content,
            description: this.description,
            publish: this.publish,
            bookmarks: JSON.stringify(this.bookmarks)
        };
    }
}
