import { render, staticRenderFns } from "./shaped-el-control.vue?vue&type=template&id=0fcd43da&"
import script from "./shaped-el-control.vue?vue&type=script&lang=ts&"
export * from "./shaped-el-control.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VCol,VColorPicker,VLabel,VRangeSlider,VRow,VSelect,VSlider})
