





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Dollar from '@/views/util/Dollar.vue';
import { Order } from '@/data/Order';

@Component({
    components: { Dollar }
})
export default class CheckoutConfirm extends Vue {
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 6;

    @Prop()
    public order!: Order;
}
