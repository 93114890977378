







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Comment } from '@/data/Comment';
import { Article } from '@/data/Article';
import { requiredRules, emailRules } from '@/views/util/Rules';

@Component
export default class VComment extends Vue {
    @Prop() readonly article !: Article;

    comments: Array<Comment> = [];
    comment: Comment = new Comment();

    requiredRules = requiredRules();
    emailRules = [ ...requiredRules(), ...emailRules() ];
    posting = false;

    public post () {
        if ((this.$refs.form as any).validate()) {
            this.posting = true;
            let comment = this.comment.getSaveData();
            comment['article_id'] = this.article.id;

            this.axios.post('api/comment/post', { comment }).then(response => {
                let comment = new Comment(response.data);
                (comment as any).isNew = true;

                this.article.comments.push(comment);
                this.$nextTick(() => {
                    this.$vuetify.goTo('#comment-' + response.data.id, { offset: 20 });
                    this.$nextTick(() => {
                        (comment as any).highlight = true;
                    });
                });
            }).finally(() => {
                this.posting = false;
            });
        }
    }
}
