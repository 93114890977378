import { IOrderItem, OrderItem } from './OrderItem';

export interface IOrder {
    id: number,
    name: string,
    email: string,
    amount: number,
    paidAmount: number,
    internalOrderId: string,
    status: string,
    shipping: string,
    note: string,
    createdAt: string,
    posted: number,
    items: Array<IOrderItem>
}

export class Order {
    public id: number = 0;
    public name: string = '';
    public email: string = '';
    public amount: number = 0;
    public paidAmount: number = 0;
    public internalOrderId: string = '';
    public status: string = '';
    public shipping: string = '';
    public items: Array<OrderItem> = [];
    public note: string;
    public posted: boolean;
    public createDate: Date;

    constructor (iOrder: IOrder) {
        this.id = iOrder.id;
        this.name = iOrder.name;
        this.email = iOrder.email;
        this.amount = iOrder.amount;
        this.paidAmount = iOrder.paidAmount;
        this.internalOrderId = iOrder.internalOrderId;
        this.status = iOrder.status;
        this.shipping = iOrder.shipping;
        this.note = iOrder.note;
        this.posted = !!iOrder.posted;
        this.createDate = new Date(iOrder.createdAt);
        iOrder.items.forEach(iOrderItem => { this.items.push(new OrderItem(iOrderItem)); });
    }

    public get orderName (): string {
        if (this.items.length) {
            return this.items.map(i => i.stock.name).join('\n');
        }

        return '---';
    }
}
