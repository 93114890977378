



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Article } from '@/data/Article';

@Component({
    metaInfo () {
        return {
            title: 'Tutorials',
            meta: [
                { name: 'description', content: 'Tutorial List.' },
                { name: 'keywords', content: 'tutorial,list' }
            ]
        };
    }
})
export default class TutorialList extends Vue {
    loading = false;
    articles:Array<Article> = [];
    mainCols = this.$vuetify.breakpoint.mdAndDown ? 12 : 6;

    public created () {
        this.loading = true;

        Vue.axios.post('/api/article/getPublishedArticle').then(response => {
            response.data.forEach((article: any) => this.articles.push(new Article(article)));
            this.loading = false;
        });
    }
}
