























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CheckoutDetail from '@/views/public/order/CheckoutDetail.vue';
import CheckoutPayment from '@/views/public/order/CheckoutPayment.vue';
import CheckoutConfirm from './CheckoutConfirm.vue';
import { Order } from '@/data/Order';
import { Nullable } from '@/types';

@Component({
    components: { CheckoutDetail, CheckoutPayment, CheckoutConfirm }
})
export default class Checkout extends Vue {
    private step = 1;
    private checkoutComplete = false;
    private paymentComplete = false;
    private confirmComplete = false;
    private order: Nullable<Order> = null;

    public doneCheckout () {
        this.checkoutComplete = true;
        this.step = 2;
    }

    public backToCheckout () {
        this.checkoutComplete = false;
        this.step = 1;
    }

    public donePayment (order: Order) {
        this.paymentComplete = true;
        this.confirmComplete = true;
        this.order = order;
        this.step = 3;
    }
}
