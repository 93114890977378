import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDateTime', function (value: string) {
    if (value) {
        return moment(value).format('MMM DD YYYY hh:mma');
    }
});

Vue.filter('formatDate', function (value: string) {
    if (value) {
        return moment(value).format('MMM DD YYYY');
    }
});

Vue.filter('dollar', function (value: number) {
    return '$' + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(value);
});
