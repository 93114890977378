











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Dollar extends Vue {
    @Prop() readonly value!: number;
    @Prop({ default: false }) readonly flat!: Boolean;

    public get formatValue (): string {
        return '$' + new Intl.NumberFormat('en-us', { minimumFractionDigits: 2 }).format(this.value);
    }
}
