import Vue from 'vue';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Vuetify from 'vuetify/lib';
// import Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';
import theme from './theme';
import '@/styles/index.scss';

Vue.use(Vuetify);

export default new Vuetify({
    theme
});
