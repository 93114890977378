






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FireFly from '@/views/decorate/background/FireFly.vue';

@Component({
    components: { FireFly }
})
export default class FireflyControl extends Vue {
    fireflyBkColor = '#000000';
    fireflyLightColor = '#0000FF';
    qty = 15;
    enabled = true;
}
