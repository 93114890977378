
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Enquiry from './Enquiry.vue';

@Component({
    components: { Enquiry },
    metaInfo () {
        return {
            title: 'Contact',
            meta: [
                { name: 'description', content: 'Hi, I\'m Ge Zhan, welcome to my site, here you can purchase some good quality lens, and i will put some of my project and tutorials about coding soon.' },
                { name: 'keywords', content: 'Ge Zhan Contact' }
            ]
        };
    }
})
export default class Contact extends Vue {
    public email = process.env.VUE_APP_ADMIN_EMAIL;
}
