





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Article } from '@/data/Article';

@Component({
    metaInfo () {
        return {
            title: (this as VArticle).article.title,
            meta: [
                { name: 'description', content: (this as VArticle).article.description },
                { name: 'keywords', content: (this as VArticle).article.title }
            ]
        };
    }
})
export default class VArticle extends Vue {
    article = new Article();
    currentEntry = 0;
    liked = false;

    public created (): void {
        Vue.axios.get('api/article/get_by_url_name/' + this.$route.params.urlName).then(response => {
            this.article.setArticleRecord(response.data);
        });
    }

    public entryVisibilityChanged (isVisible: boolean, element: any) {
        if (isVisible) {
            let bookmark = this.article.bookmarks.find(e => e.id === element.id);
            if (bookmark) {
                this.currentEntry = this.article.bookmarks.indexOf(bookmark);
            }
        }
    }

    public like () {
        if (this.article.id && !this.liked) {
            this.liked = true;
            Vue.axios.post('api/article/like', { id: this.article.id }).then(response => {
                this.article.like = response.data.like;
            });
        }
    }
}
