export function requiredRules (): any {
    return [
        (v:any) => !!v || 'Required'
    ];
}

export function emailRules (): any {
    return [
        (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ];
}
