






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FireFly extends Vue {
    @Prop({ default: 10 }) readonly qty !: number;
    @Prop({ default: 'aliceblue' }) readonly fireflyBkColor !: string;
    @Prop({ default: 'yellow' }) readonly fireflyLightColor !: string;

    public get fireflyStyle () {
        return {
            '--bk-color': this.fireflyBkColor,
            '--light-color': this.fireflyLightColor
        };
    }
}
