
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Article } from '@/data/Article';

declare const Prism: any;
// var res = Vue.compile('<div v-html="content"></div>');

@Component({
    // render: res.render
    render: function (h) {
        const render = {
            template: '<div>' + (this as any).content + '</div>',
            methods: {
                entryVisibilityChanged: (isVisible: boolean, entry: any) => {
                    (this as any).$emit('entry-visibility-changed', isVisible, entry.target);
                }
            }
        };

        return h(render);
    }
})
export default class ArticleRender extends Vue {
    @Prop() content!: string;

    public mounted () {
        Prism.highlightAll();
    }
}
